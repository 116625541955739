var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.taskTitle,"title-tag":"h3","modal-class":"custom-modal-amg","title-class":"h3 text-white font-weight-bolder","header-class":"p-0","header-bg-variant":"transparent","size":"lg","hide-footer":_vm.showMood,"no-close-on-backdrop":""},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.taskTitle))])]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":_vm.close}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button-cancel',{on:{"click":cancel}}),_c('button-save',{on:{"click":_vm.saveTask}})]}}]),model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('b-input-group',{staticClass:"mb-2",staticStyle:{"width":"40%"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"100px"}},[_vm._v(" Client ")])]},proxy:true}])},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.nameClient}})],1)],1),(!_vm.showMood)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Select a quick task","label-for":"dd","label-cols-md":"2"}},[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"8fr 3fr"}},[_c('v-select',{attrs:{"options":_vm.tasks,"label":"title"},on:{"input":_vm.selectTask},model:{value:(_vm.quickTask),callback:function ($$v) {_vm.quickTask=$$v},expression:"quickTask"}}),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.openQuickTasks}},[_c('feather-icon',{attrs:{"icon":"ListIcon"}}),_vm._v(" Quick Tasks ")],1)],1)])],1)],1):_vm._e(),_c('validation-provider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"126px"}},[_vm._v(" Subject ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null,"disabled":_vm.showMood},model:{value:(_vm.task.subject),callback:function ($$v) {_vm.$set(_vm.task, "subject", $$v)},expression:"task.subject"}})],1)]}}])}),(_vm.currentUser.role_id != 3 || [12, 3, 5].includes(_vm.moduleId))?_c('b-input-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"126px"}},[_vm._v(" Assign to: ")])]},proxy:true}],null,false,2756272177)},[_c('v-select',{staticStyle:{"width":"83.5%"},attrs:{"options":_vm.users,"label":"user_name","reduce":function (user_name) { return user_name.id; },"disabled":_vm.showMood,"clearable":!_vm.showMood},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var user_name = ref.user_name;
var name_role = ref.name_role;
var id = ref.id;
return [(id == _vm.sid || id == _vm.aid || id == _vm.id_admin)?_c('span',[(id == _vm.sid)?_c('span',[_vm._v(" "+_vm._s(_vm.sname)+" (Chief) ")]):(id == _vm.aid)?_c('span',[(_vm.sid != _vm.aid)?_c('span',[_vm._v(" "+_vm._s(_vm.aname)+" (Advisor Asigned) ")]):_vm._e()]):(_vm.id_admin != null)?_c('span',[(id === _vm.id_admin)?_c('span',[(_vm.sid != _vm.id_admin && _vm.aid != _vm.id_admin)?_c('span',[_vm._v(" "+_vm._s(_vm.name_admin)+" (Administrator) ")]):_vm._e()]):_vm._e()]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(user_name)+" "),([12, 3, 5].includes(_vm.moduleId))?_c('span',[_vm._v("("+_vm._s(name_role)+")")]):_c('span',[_vm._v("(Supervisor)")])])]}}],null,false,1448610742),model:{value:(_vm.task.user_id),callback:function ($$v) {_vm.$set(_vm.task, "user_id", $$v)},expression:"task.user_id"}})],1):_vm._e(),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"126px"}},[_vm._v(" Due Date ")])]},proxy:true}],null,true)},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker",class:errors[0]
                  ? 'w-100 rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                  : 'w-100 rounded bg-transparent k-picker-custom picker-select-date',staticStyle:{"width":"65% !important"},attrs:{"state":errors[0] ? false : valid ? true : null,"disabled":_vm.showMood,"placeholder":"Date","min-date":_vm.minDate,"max-date":_vm.maxDate,"format":'MM/dd/yyyy'},model:{value:(_vm.task.date),callback:function ($$v) {_vm.$set(_vm.task, "date", $$v)},expression:"task.date"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"126px"}},[_vm._v(" Due Time ")])]},proxy:true}],null,true)},[_c('kendo-timepicker',{staticClass:"rounded bg-transparent",class:errors[0]
                  ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                  : 'rounded bg-transparent k-picker-custom picker-select-date',staticStyle:{"height":"2.73rem","width":"65%"},attrs:{"disabled":_vm.showMood,"min":_vm.minTime,"format":'HH:mm',"value":_vm.value,"interval":15,"placeholder":"Hour"},model:{value:(_vm.task.hour),callback:function ($$v) {_vm.$set(_vm.task, "hour", $$v)},expression:"task.hour"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
return [_c('label',[_vm._v("Content")]),_c('quill-editor',{staticClass:"chat-compose-fields-header chat-compose-file-field quill-editor-tracking",attrs:{"id":"quil-content","state":errors[0] ? false : valid ? true : null,"options":_vm.editorOption,"disabled":_vm.showMood},on:{"change":function (v) { return (_vm.task.content = v.html); }},model:{value:(_vm.task.content),callback:function ($$v) {_vm.$set(_vm.task, "content", $$v)},expression:"task.content"}})]}}])})],1)],1)],1),(_vm.listQuickTasksController)?_c('list-quick-tasks-modal',{on:{"close":_vm.closeQuickTasks,"reload":_vm.getTasks}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }