<template>
  <b-modal
      v-model="onControl"
      title="LIST QUICK TASKS"
      title-tag="h3"
      modal-class="modal-primary"
      title-class="h3 text-white font-weight-bolder"
      @hidden="close"
      size="lg"
      hide-footer
      no-close-on-backdrop
  >
    <div class="d-flex justify-content-between">
      <div/>
      <b-button variant="success" class="mb-1" @click="openCreateQuickTask({}, false)"><feather-icon icon="PlusIcon"/>CREATE</b-button>
    </div>
    <filter-slot :filter-principal="{}"
                 :no-visible-principal-filter="true"
                 :total-rows="totalRows"
                 :paginate="paginate"
                 :start-page="startPage"
                 :to-page="toPage"
                 :filter="[]"
                 @reload="getQuickTasks"
    >
      <template #table>
        <b-table
            no-border-collapse
            class="mt-1 position-relative"
            :fields="fields"
            show-empty
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :items="items"
            :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(body)="data">
            <div style="word-wrap: break-word; width: 150px !important;">
              {{data.item.body}}
            </div>
          </template>
          <template #cell(created_at)="data">
            {{data.item.created_at | myGlobalWithHour}}
          </template>
          <template #cell(actions)="data">
            <feather-icon
                class="mr-1 cursor-pointer text-warning"
                icon="EditIcon"
                size="15"
                @click="openCreateQuickTask(data.item, true)"
            />
            <feather-icon
                class="mr-1 cursor-pointer text-danger"
                icon="TrashIcon"
                size="15"
                @click="deleteQuickTask(data.item.id)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <create-edit-quick-task
        v-if="createQuickTaskController"
        @close="closeCreateQuickTask"
        :quick-task="quickToSend"
        @reload="getQuickTasks"
        :is-editing-mood="isEditing"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import {mapGetters} from "vuex";
import CreateEditQuickTask from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/CreateEditQuickTask.vue";
export default {
  components: {
    CreateEditQuickTask,
  },
  async created() {
    this.onControl = true
    await this.getQuickTasks()
  },
  data() {
    return {
      onControl: false,
      createQuickTaskController: false,
      totalRows: 1,
      isBusy: false,
      startPage: 1,
      fields: [
        {
          key: 'title',
          label: 'Subject',
        },
        {
          key: 'body',
          label: 'Content',
          tdClass: 'wrap'
        },
        {
          key: 'created_at',
          label: 'Creation Date',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      items: [],
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      toPage: 0,
      quickToSend: {},
      isEditing: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.$emit('reload')
    },
    async getQuickTasks() {
      try {
        this.isBusy = true
        const data = await ClientsOptionsServices.getQuickTasks({
          id: this.currentUser.user_id,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        })
        if (data.status === 200) {
          this.items = data.data.data
          this.isBusy = false
          this.startPage = data.data.from
          this.paginate.currentPage = data.data.current_page
          this.paginate.perPage = data.data.per_page
          this.totalRows = data.data.total
          this.toPage = data.data.to
          this.nextPage = this.startPage + 1
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async deleteQuickTask(id){
      try {
        const result = await this.showConfirmSwal()
        if (result.isConfirmed) {
          this.addPreloader()
          const data = await ClientsOptionsServices.deleteQuickTask({
            id: id,
          })
          if (data.status === 200) {
            this.removePreloader()
            this.showSuccessSwal()
            await this.getQuickTasks()
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    closeCreateQuickTask() {
      this.createQuickTaskController = false
      this.isEditing = false
      this.quickToSend={}
    },
    openCreateQuickTask(quick, edit) {
      this.createQuickTaskController = true
      this.quickToSend=quick
      this.isEditing=edit
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
}
</script>

<style scoped>
.wrap{
  word-wrap: break-word !important;
  overflow-wrap: break-word;
}
</style>