var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.title,"title-tag":"h3","modal-class":"modal-primary","title-class":"h3 text-white font-weight-bolder","size":"lg","no-close-on-backdrop":""},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button-cancel',{on:{"click":function($event){return cancel()}}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveTask}},[(_vm.spinnerOn)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Small Spinner"}}):_vm._e(),_vm._v("Save")],1)]}}]),model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('validation-observer',{ref:"form"},[_c('validation-provider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"126px"}},[_vm._v("Subject")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.task.title),callback:function ($$v) {_vm.$set(_vm.task, "title", $$v)},expression:"task.title"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticStyle:{"width":"126px"}},[_vm._v("Content")])]},proxy:true}],null,true)},[_c('b-form-textarea',{staticStyle:{"height":"6rem"},attrs:{"state":errors[0] ? false : valid ? true : null,"no-resize":""},model:{value:(_vm.task.body),callback:function ($$v) {_vm.$set(_vm.task, "body", $$v)},expression:"task.body"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }