<template>
  <b-modal
    v-model="onControl"
    title="ALL TASKS"
    title-tag="h3"
    modal-class="custom-modal-amg"
    header-class="p-0"
    header-bg-variant="transparent"
    title-class="h3 text-white font-weight-bolder"
    @hidden="close"
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>ALL TASKS</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <div class="d-flex justify-content-center mb-2">
      <!--      <b-input-group class="mb-2" style="width: 40%">-->
      <!--        <template #prepend>-->
      <!--          <b-input-group-text style="width: 100px" >Client</b-input-group-text>-->
      <!--        </template>-->
      <!--        <b-form-input disabled :value="nameClient"></b-form-input>-->
      <!--      </b-input-group>-->
      <b-input-group style="width: 65%">
        <template #prepend>
          <b-input-group-text class="title_lable d-flex justify-content-center"
            >{{ titleLabel }}
          </b-input-group-text>
        </template>

        <span
          style="'border-color:rgba(255, 255, 255, 0.4)'; "
          :style="
            isDarkSkin
              ? 'background: #17171A;opacity: 0.7;color:white'
              : 'background: #efefef'
          "
          class="form-control text-center"
          >{{ nameClient }}</span
        >
      </b-input-group>
    </div>
    <filter-slot
      :filter-principal="{}"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter="[]"
      :no-visible-principal-filter="true"
      @reload="getCompletedTasks"
    >
      <template #table>
        <b-table
          no-border-collapse
          class="table-new-customization custom-table"
          :fields="fields"
          show-empty
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :items="items"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(date)="data">
            {{ data.item.date | myGlobalWithHour }}
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    nameClient: null,
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      totalRows: 1,
      startPage: 0,
      toPage: 0,
      fields: [
        {
          key: "subject",
          label: "Subject",
        },
        {
          key: "content",
          label: "Content",
        },
        {
          key: "date",
          label: "Date / Hour",
        },
      ],
      items: [],
      titleLabel: "ACCOUNT",
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
    };
  },
  async created() {
    this.onControl = true;
    if (this.moduleId === 7) {
      this.titleLabel = "CLIENT";
    }
    await this.getCompletedTasks();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getCompletedTasks() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.getCompletedTasks({
          id: this.$route.params.idClient,
          user_id: this.currentUser.user_id,
          perpage: this.paginate.perPage,
        });
        if (data.status === 200) {
          this.items = data.data.data;
          this.isBusy = false;
          this.startPage = data.data.from;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.totalRows = data.data.total;
          this.toPage = data.data.to;
          this.nextPage = this.startPage + 1;
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
