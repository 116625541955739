<template>
  <b-modal
      v-model="onControl"
      :title="title"
      title-tag="h3"
      modal-class="modal-primary"
      title-class="h3 text-white font-weight-bolder"
      @hidden="close"
      size="lg"
      no-close-on-backdrop
  >
    <validation-observer ref="form">
      <validation-provider
          v-slot="{ errors, valid }"
          name="subject"
          rules="required"
      >
    <b-input-group class="mb-2">
      <template #prepend>
        <b-input-group-text style="width: 126px" >Subject</b-input-group-text>
      </template>

      <b-form-input :state="errors[0] ? false : valid ? true : null"   v-model="task.title"></b-form-input>
    </b-input-group>
      </validation-provider>

      <validation-provider
          v-slot="{ errors, valid }"
          name="content"
          rules="required"
      >
    <b-input-group class="mb-2">
      <template #prepend>
        <b-input-group-text style="width: 126px">Content</b-input-group-text>
      </template>
      <b-form-textarea :state="errors[0] ? false : valid ? true : null" no-resize style="height: 6rem" v-model="task.body"></b-form-textarea>
    </b-input-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer="{cancel}">
      <button-cancel @click="cancel()"/>
      <b-button variant="primary" @click="saveTask"><b-spinner v-if="spinnerOn" small label="Small Spinner" class="mr-1"></b-spinner>Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import {mapGetters} from "vuex";

export default {
  props: {
    isEditingMood: null,
    quickTask: null,
  },
  components: {
    ButtonSave,
    ButtonCancel,
  },
  data(){
    return {
      onControl: false,
      task: {
        id: this.quickTask.id,
        title: this.quickTask.title,
        body: this.quickTask.body,
      },
      title: '',
      spinnerOn : false,
    }
  },
  async created() {
    this.onControl = true
    this.title = this.isEditingMood? 'EDIT QUICK TASK' : 'CREATE QUICK TASK'
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async saveTask(){
      try {
        const res = await this.$refs.form.validate();
        if (res) {
          this.spinnerOn = true
          const data = await ClientsOptionsServices.saveQuickTask({
            id: this.task.id,
            title: this.task.title,
            body: this.task.body,
            user_id: this.currentUser.user_id,
          })
          if (data.status === 200){
            this.spinnerOn = false
            this.close()
            this.$emit('reload')
            this.showSuccessSwal()
          }
        }
      } catch (e) {
        this.spinnerOn = true
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>

</style>