<template>
  <b-modal
    v-model="onControl"
    :title="taskTitle"
    title-tag="h3"
    modal-class="custom-modal-amg"
    title-class="h3 text-white font-weight-bolder"
    header-class="p-0"
    header-bg-variant="transparent"
    size="lg"
    :hide-footer="showMood"
    no-close-on-backdrop
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>{{ taskTitle }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <div class="w-100 d-flex justify-content-center">
        <b-input-group class="mb-2" style="width: 40%">
          <template #prepend>
            <b-input-group-text style="width: 100px">
              Client
            </b-input-group-text>
          </template>
          <b-form-input disabled :value="nameClient" />
        </b-input-group>
      </div>
      <b-row v-if="!showMood">
        <b-col cols="12">
          <b-form-group
            label="Select a quick task"
            label-for="dd"
            label-cols-md="2"
          >
            <div style="display: grid; grid-template-columns: 8fr 3fr">
              <v-select
                v-model="quickTask"
                :options="tasks"
                label="title"
                @input="selectTask"
              />
              <b-button variant="primary" class="ml-1" @click="openQuickTasks">
                <feather-icon icon="ListIcon" /> Quick Tasks
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <validation-provider
        v-slot="{ errors, valid }"
        name="subject"
        rules="required"
      >
        <b-input-group class="mb-2">
          <template #prepend>
            <b-input-group-text style="width: 126px">
              Subject
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="task.subject"
            :state="errors[0] ? false : valid ? true : null"
            :disabled="showMood"
          />
        </b-input-group>
      </validation-provider>
      <b-input-group
        v-if="currentUser.role_id != 3 || [12, 3, 5].includes(moduleId)"
        class="mb-2"
      >
        <template #prepend>
          <b-input-group-text style="width: 126px">
            Assign to:
          </b-input-group-text>
        </template>
        <v-select
          v-model="task.user_id"
          style="width: 83.5%"
          :options="users"
          label="user_name"
          :reduce="(user_name) => user_name.id"
          :disabled="showMood"
          :clearable="!showMood"
        >
          <template #option="{ user_name, name_role, id }">
            <span v-if="id == sid || id == aid || id == id_admin">
              <span v-if="id == sid"> {{ sname }} (Chief) </span>
              <span v-else-if="id == aid">
                <span v-if="sid != aid"> {{ aname }} (Advisor Asigned) </span>
              </span>
              <span v-else-if="id_admin != null">
                <span v-if="id === id_admin">
                  <span v-if="sid != id_admin && aid != id_admin">
                    {{ name_admin }} (Administrator)
                  </span>
                </span>
              </span>
            </span>
            <span v-else>
              {{ user_name }}
              <span v-if="[12, 3, 5].includes(moduleId)"
                >({{ name_role }})</span
              >
              <span v-else>(Supervisor)</span>
            </span>
          </template>
        </v-select>
      </b-input-group>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors, valid }"
            name="date"
            rules="required"
          >
            <b-input-group class="mb-2">
              <template #prepend>
                <b-input-group-text style="width: 126px">
                  Due Date
                </b-input-group-text>
              </template>
              <kendo-datepicker
                v-model="task.date"
                v-mask="'##/##/####'"
                style="width: 65% !important"
                :state="errors[0] ? false : valid ? true : null"
                :disabled="showMood"
                placeholder="Date"
                :class="
                  errors[0]
                    ? 'w-100 rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                    : 'w-100 rounded bg-transparent k-picker-custom picker-select-date'
                "
                :min-date="minDate"
                :max-date="maxDate"
                :format="'MM/dd/yyyy'"
                class="leads-datepicker"
              />
            </b-input-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider v-slot="{ errors }" name="time" rules="required">
            <b-input-group class="mb-2">
              <template #prepend>
                <b-input-group-text style="width: 126px">
                  Due Time
                </b-input-group-text>
              </template>
              <kendo-timepicker
                v-model="task.hour"
                :disabled="showMood"
                :min="minTime"
                :format="'HH:mm'"
                :value="value"
                :interval="15"
                class="rounded bg-transparent"
                placeholder="Hour"
                style="height: 2.73rem; width: 65%"
                :class="
                  errors[0]
                    ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                    : 'rounded bg-transparent k-picker-custom picker-select-date'
                "
              />
            </b-input-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            name="content"
            rules="required"
          >
            <!-- <b-input-group class="mb-2"> -->
            <!-- <template #prepend> -->
            <!-- <b-input-group-text style="width: 126px"> -->
            <label>Content</label>
            <!-- </b-input-group-text>
          </template> -->
            <!-- <b-form-textarea
            v-model="task.content"
            :state="errors[0] ? false : valid ? true : null"
            :disabled="showMood"
            style="height: 6rem"
          /> -->
            <quill-editor
              id="quil-content"
              :state="errors[0] ? false : valid ? true : null"
              v-model="task.content"
              :options="editorOption"
              @change="(v) => (task.content = v.html)"
              class="chat-compose-fields-header chat-compose-file-field quill-editor-tracking"
              :disabled="showMood"
            />
            <!-- </b-input-group> -->
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel" />
      <button-save @click="saveTask" />
    </template>
    <list-quick-tasks-modal
      v-if="listQuickTasksController"
      @close="closeQuickTasks"
      @reload="getTasks"
    />
  </b-modal>
</template>
<script>
import vSelect from "vue-select";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import ListQuickTasksModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListQuickTasksModal.vue";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    vSelect,
    ButtonSave,
    ButtonCancel,
    ListQuickTasksModal,
    quillEditor,
  },
  props: {
    nameClient: null,
    taskData: null,
    aid: null,
    aname: null,
    sid: null,
    sname: null,
    toEdit: null,
    id_spec: null,
    id_admin: null,
    name_admin: null,
    showMood: false,
  },
  data() {
    return {
      editorOption: {
        modules: { toolbar: false },
      },
      onControl: false,
      listQuickTasksController: false,
      quickTask: "",
      value: "00:00 AM",
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      minTime: "",
      task: {
        id: "",
        idtask: this.taskData.id || "",
        subject: this.taskData.subject,
        date: this.taskData.date1,
        hour: this.taskData.hour,
        content: this.taskData.content,
        created: "",
        user_id: this.taskData.asigned,
        modul: "",
      },
      tasks: [],
      users: [],
      taskTitle: this.taskData.id != null ? "Edit Task" : "Add Task",
    };
  },
  async created() {
    this.onControl = true;
    this.addPreloader();
    if (this.toEdit || this.showMood) {
      this.task = { ...this.taskData };
      this.$set(this.task, "date", this.taskData.date1);
      this.task.idtask = this.taskData.id;
    }
    this.task.id = this.$route.params.idClient;
    this.task.created = this.currentUser.user_id;
    this.minTime = new Date(2019, 11, 24, 6, 0);
    if (this.showMood) this.taskTitle = "Show Task";
    await this.getTasks();
    await this.getUsers();
    this.removePreloader();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveTask() {
      try {
        const res = await this.$refs.form.validate();
        if (res) {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            this.task.modul = this.moduleId,
            this.task.user_id =
              this.task.user_id == "" || !this.task.user_id
                ? this.currentUser.user_id
                : this.task.user_id;
            const data = await ClientsOptionsServices.addTaskCredit(this.task);
            if (data.status === 200) {
              this.$emit("reload");
              this.showSuccessSwal();
              this.close();
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getTasks() {
      try {
        const data = await ClientsOptionsServices.getQuickTasks({
          id: this.currentUser.user_id,
        });
        if (data.status === 200) {
          this.tasks = data.data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    openQuickTasks() {
      this.listQuickTasksController = true;
    },
    closeQuickTasks() {
      this.listQuickTasksController = false;
    },
    async getUsers() {
      try {
        const data = await ClientsOptionsServices.getClientsByModuleAndRole(
          this.moduleId,
          {
            roles: "[2,3]",
            type: "1",
            idspec: this.id_spec != null ? this.id_spec : 0,
          }
        );
        this.users = data.data;
        if (this.name_admin != null) {
          this.users.unshift({
            user_name: this.name_admin,
            id: this.id_admin,
          });
        }
        this.users.unshift({
          user_name: this.aname,
          id: this.aid,
        });
        this.users.unshift({
          user_name: this.sname,
          id: this.sid,
        });
        if (this.moduleId === 12) {
          if (this.users[0].id === 41) {
            this.users.shift();
          }
        }

        // remove duplicate users by id
        this.users = this.users.filter(
          (item, index) =>
            this.users.findIndex((x) => x.id === item.id) === index
        );
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    selectTask() {
      this.$set(this.task, "content", this.quickTask.body);
      this.$set(this.task, "subject", this.quickTask.title);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>

<style scoped>
.prepend {
  border-radius: 0.357rem 0 0 0.357rem;
  height: 35px;
  margin-bottom: 0;
  color: #6e6b7b;
  background-color: transparent;
  border: 1px solid #d8d6de;
  padding-right: 0 !important;
}
.prepend-dark {
  border-radius: 0.357rem 0 0 0.357rem;
  height: 35px;
  margin-bottom: 0;
  color: #d8d6de !important;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding-right: 0 !important;
}
</style>
<style lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
.picker-select-date {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #fff !important;
  .leads-datepicker {
    height: 35px !important;
    border: none !important;
  }
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  input::placeholder {
    color: #ccccd3;
    font-weight: 400;
  }
}
.dark-layout .picker-select-date {
  background: #17171a !important;
  input::placeholder {
    color: #4f4f52 !important;
    font-weight: 600;
  }
}
.border-error-datepicker {
  border: 1px solid #fc424a;
  .k-icon {
    color: #fc424a;
  }
  &:hover {
    border: 1px solid #fc424a;
  }
}
</style>
<style>
.kk-picker-wrapp .k-timepicker .k-picker-wrap {
  border: 1px solid red !important;
}
.pac-container.pac-logo:after {
  display: none !important;
}
</style>
